import React from 'react';
import type { AppProps } from 'next/app';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import GlobalStyle from 'components/globalstyles';

const theme = extendTheme({
  // fonts: {
  //   heading: `'Roboto Condensed', cursive`,
  //   body: `'Roboto', sans-serif`,
  // }
});

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <GlobalStyle />
      <ChakraProvider theme={theme}>
        <Component {...pageProps} />
      </ChakraProvider>
    </>
  );
}
