// import { sanitizeConfig } from './utils';

const config = {
  markup: '1.03',
  env: process.env.NEXT_PUBLIC_ENV || 'test',
  showDebugView: false,
  stripePublishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_TEST,
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN
};

if (config.env === 'production') {
  config.stripePublishableKey =
    process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_LIVE;
}

// console.log(sanitizeConfig(config));

export default config;
